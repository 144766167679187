import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'

const Listing = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Container>
        <Heading>{data.contentfulListing.name}</Heading>
        <Description>
          {data.contentfulListing.description.description}
        </Description>
        <KeyInfo />
      </Container>
    </Layout>
  )
}

export default Listing

export const query = graphql`
  query($id: String!) {
    contentfulListing(contentful_id: { eq: $id }) {
      id
      name
      tags
      approved
      rating
      description {
        description
      }
    }
  }
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
`

const Heading = styled.h1`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-bottom: 5px;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`

const Description = styled.p`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
  margin-bottom: 10px;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`

const KeyInfo = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 6;

  @media (min-width: ${props => props.theme.breaks.medium}) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
  }
`
